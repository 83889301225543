import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { retry } from "../common/utils/CommonFunctions";
import { useAuthContext } from "../hooks/useAuthContext";

const Home = lazy(() => retry(() => import("../pages/Home")));
const Sidebar = lazy(() => retry(() => import("../layout/Sidebar")));
const Login = lazy(() => retry(() => import("../pages/Login")));
const FourOhFourHome = lazy(() =>
  retry(() => import("../pages/FourOhFourHome"))
);
const PageUnderConstruction = lazy(() =>
  retry(() => import("../pages/PageUnderConstruction"))
);

const Routes = () => {
  const { token } = useAuthContext();

  const routes = useRoutes([
    {
      path: "/",
      element: <Home to="/" />,
    },
    {
      path: "/admin/login",
      element: !token ? <Login /> : <Navigate replace to="/admin/to-do" />,
    },
    {
      path: "/admin",
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: "/admin/*",
      element: token ? <Sidebar /> : <Navigate replace to="/admin/login" />,
    },
    {
      path: "/*",
      element: <Navigate replace to="/404" />,
    },
    {
      path: "/404",
      element: <FourOhFourHome />,
    },
    {
      path: "/503",
      element: <PageUnderConstruction />,
    },
  ]);

  return routes;
};

export default Routes;
