import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { AuthContextProvider } from "./context/AuthContext";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import Routes from "./routes/Routes";
import ThemeProvider from "./theme";

// Styling for SnackbarProvider
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #401f08;
  }
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-family: Manrope, sans-serif;
  }
`;

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledSnackbarProvider
              hideIconVariant
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {loading ? <Loader /> : <Routes />}
            </StyledSnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </AuthContextProvider>
  );
};

export default App;
