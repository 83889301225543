import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props;

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: 600,
          fontSize: "14px",
          fontFamily: "Manrope, sans-serif",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: "0px",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Manrope, sans-serif",
        },
        notchedOutline: {
          borderColor: "#401f08",
          borderWidth: "1.5px",
          borderRadius: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#401f08",
            },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          ".MuiIconButton-edgeEnd": {
            backgroundColor: "#FF8600",
            borderRadius: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
          backgroundColor: "#FFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          height: "58px",
          boxShadow: "none",
          fontSize: "16px",
          fontWeight: 700,
          minWidth: "115px",
          padding: "6px 25px",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 10px 0px #0612370F",
          borderRadius: "0px",
          padding: "8px",
          gap: "10px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          ":last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 10px 0px #0612370F",
          borderRadius: "0px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 800,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#FFFFFF",
          color: "#F4C524",
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.04)",
        },
        arrow: {
          color: "#FFFFFF",
          "::before": {
            border: "1px solid #0612370F",
          },
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
