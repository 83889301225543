import React from "react";
import { Box, CircularProgress } from "@mui/material";
import LottieLoader from "react-lottie-loader";
import { Helmet } from "react-helmet-async";
import loadingAnimation from "../animation/loading-animation.json";

const Loader = () => {
  return (
    <>
      <Helmet>
        <title>Home | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f4c524",
        }}
      >
        <Box
          sx={{
            width: 300,
            height: 300,
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    </>
  );
};

export default Loader;
